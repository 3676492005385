import { toJS } from 'mobx';
import {
  types,
  flow,
  getParentOfType,
  getSnapshot,
} from 'mobx-state-tree';
import AsyncStatusModel from './AsyncStatus';
import { firebase } from '../components/Firebase';
import {
  generateId,
  firestoreSnapshotToArray,
  createEditorStateJSON,
} from '../common/utils';

export const DocumentBase = types
  .model('Document', {
    id: types.optional(types.identifier, () => generateId()),
    title: 'Untitled',
    content: types.frozen(null),
    createdAt: types.frozen(),
    editedAt: types.frozen(),
    // viewedAt: types.maybe(types.string), // ToDo
  })
  .preProcessSnapshot(snapshot => {
    const { content, ...rest } = snapshot;

    if (typeof content === 'string') {
      return {
        ...rest,
        content: createEditorStateJSON(content),
      };
    }

    return snapshot;
  });

export const Document = DocumentBase.named('Document')
  .views(self => ({
    get documentList() {
      return getParentOfType(self, DocumentList);
    },
    get store() {
      return getParentOfType(self, Store);
    },
    get user() {
      return self.store.user;
    },
    serialize: (toJSON = true) => {
      return toJSON ? getSnapshot(self) : toJS(self);
    },
  }))
  .volatile(self => ({
    docRef: firebase.userDocument(self.user.uid, self.id),
  }))
  .actions(self => ({
    setTitle: flow(function*(title) {
      // ToDo: validation
      yield self.docRef.update({
        title,
        editedAt: firebase.fieldValue.serverTimestamp(),
      });
    }),
    setContent: flow(function*(content) {
      // ToDo: validation
      yield self.docRef.update({
        content: JSON.stringify(content),
        editedAt: firebase.fieldValue.serverTimestamp(),
      });
    }),
    remove() {
      self.docRef.delete();
    },
  }));

export const DocumentList = types
  .model('DocumentList', {
    documents: types.optional(types.array(Document), []),
    limit: 100,
    requestStatus: types.optional(AsyncStatusModel, {}),
  })
  .views(self => ({
    get store() {
      return getParentOfType(self, Store);
    },
    get user() {
      return self.store.user;
    },
    findDocumentById: id => {
      return self.documents.find(doc => doc.id === id);
    },
    serialize: (toJSON = true) => {
      return toJSON ? getSnapshot(self) : toJS(self);
    },
  }))
  .actions(self => {
    let documentsSnapshotDisposer;

    return {
      createAndAddDocument: flow(function*(props) {
        const newDocRef = firebase.userDocuments(self.user.uid).doc();
        const document = DocumentBase.create({
          id: newDocRef.id,
          ...props,
        });
        const { id, ...serialized } = getSnapshot(document);

        yield newDocRef.set({
          ...serialized,
          content: JSON.stringify(serialized.content),
          createdAt: firebase.fieldValue.serverTimestamp(),
          editedAt: firebase.fieldValue.serverTimestamp(),
        });

        return document;
      }),
      setDocuments(documents) {
        self.documents = documents;
      },
      fetchDocuments(userId = self.user.uid) {
        self.requestStatus.setPending();
        documentsSnapshotDisposer = firebase
          .userDocuments(userId)
          .orderBy('createdAt', 'desc')
          .limit(self.limit)
          .onSnapshot(snapshot => {
            self.requestStatus.setResolved();
            // ToDo: handle errors
            self.setDocuments(firestoreSnapshotToArray(snapshot));
          });
      },
      removeDocument(documentId) {
        firebase.userDocument(self.user.uid, documentId).remove();
      },
      beforeDestroy() {
        documentsSnapshotDisposer && documentsSnapshotDisposer();
      },
    };
  });

export const Store = types
  .model('Store', {
    documentList: types.optional(DocumentList, {}),
    user: types.optional(types.frozen(), null),
  })
  .actions(self => ({
    setUser(user) {
      self.user = user;

      if (typeof window !== 'undefined' && user) {
        window.Intercom('update', {
          email: user.email,
        });
      } else if (typeof window !== 'undefined') {
        window.Intercom('update', {
          email: undefined,
        });
      }
    },
  }));
