import React from 'react';
import { trimStart, castArray } from 'lodash-es';
import { DateTime } from 'luxon';
import { Location } from '@reach/router';
import nanoidGenerate from 'nanoid/generate';
import nanoidDictionary from 'nanoid-dictionary/lowercase';
import { EditorState } from 'prosemirror-state';
import { formulaSchema } from '../components/Editor/schema';

export const withLocation = Component => props => (
  <Location>
    {({ location }) => <Component location={location} {...props} />}
  </Location>
);

export const convertPathToArray = path =>
  trimStart(path, '/').split('/');

// function to check if pathname is matching desired tokens
// i.e. isPathMatching("/home/j4jj2fm", ["home", "j4jj2fm"]) === true
export const isPathMatchingTokens = (pathname = '', tokens = []) => {
  const pathAsArray = convertPathToArray(pathname);
  return castArray(tokens).every(
    (part, i) => pathAsArray[i] === part,
  );
};

export const getParamInPathname = (
  pathname = '',
  tokensBefore = [],
) => {
  const pathAsArray = convertPathToArray(pathname);
  return pathAsArray[castArray(tokensBefore).length];
};

export const firestoreSnapshotToArray = snapshot => {
  if (snapshot.size) {
    const array = [];
    snapshot.forEach(doc => {
      const data = doc.data();
      array.push({
        ...data,
        content: JSON.parse(data.content),
        id: doc.id,
      });
    });

    return array;
  }

  return [];
};

export const generateId = (length = 5) =>
  nanoidGenerate(nanoidDictionary, length);

export const createEditorStateJSON = text => {
  let editorState = EditorState.create({
    schema: formulaSchema,
  });

  if (text) {
    editorState = editorState.apply(
      editorState.tr.insertText(`${text}`),
    );
  }

  return editorState.doc.toJSON();
};

export const formatTimestampToDateWithSeconds = timestamp =>
  timestamp.toDate().toLocaleString(DateTime.DATETIME_SHORT);
