import { types } from 'mobx-state-tree';
import { values } from 'lodash-es';
import { ASYNC_STATES } from '../common/config';

export default types
  .model('AsyncStatus', {
    status: types.optional(
      types.enumeration(values(ASYNC_STATES)),
      ASYNC_STATES.NONE,
    ),
  })
  .views(self => ({
    get isNone() {
      return self.status === ASYNC_STATES.PENDING;
    },
    get isPending() {
      return self.status === ASYNC_STATES.PENDING;
    },
    get isResolved() {
      return self.status === ASYNC_STATES.RESOLVED;
    },
    get isRejected() {
      return self.status === ASYNC_STATES.REJECTED;
    },
  }))
  .actions(self => ({
    setPending: () => (self.status = ASYNC_STATES.PENDING),
    setResolved: () => (self.status = ASYNC_STATES.RESOLVED),
    setRejected: errorMessage =>
      (self.status = ASYNC_STATES.REJECTED),
    reset: () => (self.status = ASYNC_STATES.NONE),
  }));
