import { Schema } from 'prosemirror-model';
import { addListNodes } from 'prosemirror-schema-list';
import { schema } from 'prosemirror-schema-basic';
import { nodes } from '@reactivepad/prosemirror';

export const formulaSchema = new Schema({
  nodes: addListNodes(
    schema.spec.nodes,
    'paragraph block*',
    'block',
  ).append(nodes),
  marks: schema.spec.marks,
});
