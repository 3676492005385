import React from 'react';
import { Link } from 'gatsby';

import { AuthUserContext } from '../Session';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';

const ROUTES_NAMES_MAPPED = [
  {
    route: ROUTES.HOME,
    name: 'Documents',
  },
  {
    route: ROUTES.ACCOUNT,
    name: 'Account',
  },
];

const Navigation = () => (
  <AuthUserContext.Consumer>
    {authUser => authUser ? (<NavigationAuth authUser={authUser} />) : (<NavigationNonAuth />)}
  </AuthUserContext.Consumer>
);

const NavigationAuth = ({ authUser }) => (
  <ul>
    {ROUTES_NAMES_MAPPED.map(({ route, name }, i) => (
      <li className="dib mr2" key={i}>
        <Link to={route}>{name}</Link>
      </li>
    ))}
    <li className="dib mr2">
      <SignOutButton />
    </li>
  </ul>
);

const NavigationNonAuth = () => (
  <ul>
    <li className="dib mr2">
      <Link to={ROUTES.LANDING}>Landing</Link>
    </li>
    <li className="dib mr2">
      <Link to={ROUTES.SIGN_IN}>Sign In</Link>
    </li>
  </ul>
);

export default Navigation;
