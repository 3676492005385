import React from 'react';

import { withFirebase } from '../Firebase';

const SignOutButton = ({ firebase }) => (
  <button
    type="button"
    className="f6 link dim br3 ph3 pv2 mb2 dib white bg-dark-gray pointer"
    onClick={firebase ? firebase.doSignOut : () => {}}
  >
    Sign Out
  </button>
);

export default withFirebase(SignOutButton);
