import React, { Component, Fragment } from 'react';
import { Provider, observer } from 'mobx-react';
import { Global, css } from '@emotion/core';
import {
  ThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import 'tachyons/css/tachyons.min.css';
import './Editor/prosemirror.css';

import Navigation from './Navigation';
import { firebase, FirebaseContext } from './Firebase';
import withAuthentication from './Session/withAuthentication';
import { Store } from '../store/Store';
import { globalStyles, theme } from '../common/styles';

// ToDo:
// https://github.com/mobxjs/mobx-react#server-side-rendering-with-usestaticrendering
// useStaticRendering(true);

const muiTheme = createMuiTheme(theme);

class Layout extends Component {
  state = {
    firebase,
    store: Store.create(),
  };

  render() {
    const { store, firebase } = this.state;

    // ToDo: make sure it's safe
    if (!this.state.firebase) {
      return null;
    }

    return (
      <ThemeProvider theme={muiTheme}>
        <Provider store={store}>
          <FirebaseContext.Provider value={firebase}>
            <AppWithAuthentication {...this.props} />
          </FirebaseContext.Provider>
        </Provider>
      </ThemeProvider>
    );
  }
}

const wholeScreenStyles = css`
  min-height: 100vh;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const wholeScreenContentStyles = css`
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0;
`;

const hrStyles = css`
  margin-block-end: 0;
`;

const AppWithAuthentication = withAuthentication(
  ({ wholeScreen = false, children }) => {
    const content = (
      <Fragment>
        <Global styles={globalStyles} />
        {children}
      </Fragment>
    );

    if (wholeScreen) {
      return (
        <div css={wholeScreenStyles}>
          <div css={wholeScreenContentStyles}>{content}</div>
        </div>
      );
    }

    return (
      <Fragment>
        <Navigation />
        <hr css={hrStyles} />
        {content}
      </Fragment>
    );
  },
);

export default observer(Layout);
