import firebaseApp from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};

const firebaseInitialized = typeof window !== "undefined" ? firebaseApp.initializeApp(config) : {};
const db = typeof window !== "undefined" ? firebaseApp.firestore(firebaseInitialized) : {};
const auth = typeof window !== "undefined" ? firebaseApp.auth(firebaseInitialized) : {};


const user = uid => db.doc(`users/${uid}`);
const onAuthUserListener = (next, fallback) => {
  return auth.onAuthStateChanged(authUser => {
    if (authUser) {
      user(authUser.uid)
        .get()
        .then(snapshot => {
          const dbUser = snapshot.data() || {};

          // default empty roles
          if (!dbUser.roles) {
            dbUser.roles = {};
          }

          // merge auth and db user
          authUser = {
            uid: authUser.uid,
            email: authUser.email,
            emailVerified: authUser.emailVerified,
            providerData: authUser.providerData,
            ...dbUser,
          };

          next(authUser);
        });
    } else {
      fallback();
    }
  });
};

export const firebase = {
  doCreateUserWithEmailAndPassword: (email, password) =>
    auth.createUserWithEmailAndPassword(email, password),
  doSignInWithEmailAndPassword: (email, password) =>
    auth.signInWithEmailAndPassword(email, password),
  doSignInAnonymously: () => auth.signInAnonymously(),
  doSignOut: () => auth.signOut(),
  doPasswordReset: email => auth.sendPasswordResetEmail(email),
  doSendEmailVerification: () =>
    auth.currentUser.sendEmailVerification({
      url: process.env.FIREBASE_CONFIRMATION_EMAIL_REDIRECT,
    }),
  doPasswordUpdate: password =>
    auth.currentUser.updatePassword(password),
  user,
  users: () => db.collection('users'),
  // *** Document API ***
  userDocument: (userId, docId) =>
    db.doc(`/users/${userId}/documents/${docId}`),
  userDocuments: userId =>
    db.collection(`/users/${userId}/documents`),
  onAuthUserListener,
  fieldValue: firebaseApp.firestore.FieldValue,
  auth,
};
