import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'recompose';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);
      // todo: change this ugly hack  
      const authUserFromLocalStorage = typeof window !== 'undefined' ? localStorage.getItem('authUser') : null;
      const parsed = authUserFromLocalStorage !== undefined
        ? JSON.parse(authUserFromLocalStorage)
        : null;

      this.props.store.setUser(parsed);
    }

    componentDidMount() {
      this.listener = this.props.firebase.onAuthUserListener(
        authUser => {
          localStorage.setItem('authUser', JSON.stringify(authUser));
          this.props.store.setUser(authUser);
        },
        () => {
          localStorage.removeItem('authUser');
          this.props.store.setUser(null);
        },
      );
    }
    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.props.store.user}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return compose(
    withFirebase,
    inject('store'),
    observer,
  )(WithAuthentication);
};

export default withAuthentication;
