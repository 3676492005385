import { pick } from 'lodash-es';
import { css } from '@emotion/core';

export const PALETTE = {
  white: '#fff',
  gray: '#f6f6f6',
  gray2: '#e3e3e3',
  gray3: '#909090',
  dark: '#2e2e2e',
  black: '#000',
  blue: '#086dd6',
  blue2: '#edf2f7',
  blue3: '#e1e6ea',
};

export const FONT_SIZES = {
  h1: '1.3rem',
  h2: '1.2rem',
  h3: '1.1rem',
};

export const theme = {
  typography: {
    fontFamily: ['Avenir Next', 'sans-serif'].join(','),
    fontSize: 13,
    button: {
      textTransform: 'initial',
    },
  },
};

export const globalStyles = css({
  body: pick(theme.typography, ['fontFamily', 'fontSize']),
});

export const scrollable = css({
  height: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
});

export const infiniteScroll = css({
  overflowX: 'hidden',
  height: 'inherit',
});
